<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>
  <van-dialog v-model="show">
    <div class="dialog_info">
      <ul v-if="childrenTypeList.length > 0">
        <li v-for="(item, index) in childrenTypeList" :key="item.label">
          <div class="left_dian"><span :class="'color' + (index % 4)"></span></div>
          <div class="right_text">
            <p>{{item.label}}</p>
            <span>{{item.num}}人{{item.per ? '|' + item.per + '%' : ''}}</span>
          </div>
        </li>
      </ul>
      <van-empty v-else description="没有二级标签哦" />
    </div>
  </van-dialog>

  <div>
    <div class="container">
      <title-cop  :title="(isStreet ? '社区' : '社区' ) + '居民情况'"/>
      <div class="user_status"><total-number :countList="informationList" :resCount="informationCount" :unit="'人'" /></div>
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '社区' : '社区' ) + '居民男女比例'"/>
      <sex-progress :man="man" :woman="woman"/>
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '社区' : '社区' ) + '居民各年龄划分'"/>
      <div class="user_age"><width-bar :labelCount="ageList" :labelName="ageName" :dataName="'居民年龄统计'"/></div>
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '社区' : '社区' ) + '居民标签统计'"/>
      <user-type @lookInfo="lookInfo" :list="typeList" :typeCount="informationCount" />
    </div>

    <div class="container">
      <title-cop  :title="(isStreet ? '社区' : '社区' ) + '人口变动情况'"/>
      <div class="peple_change"><line-cop ref="lineCop" :labelName="changeName" :countList="changeValue" /></div>
    </div>
  </div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalNumber from './components/total-Number'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import lineCop from './components/line-cop.vue'
export default {
  components: {
    titleCop,
    totalNumber,
    SexProgress,
    widthBar,
    orgChange,
    UserType,
    lineCop
  },
  data () {
    return {
      grid: 0,
      isStreet: true,
      orgList: [],
      show: false,
      list: [
        {name: '1', value: 200, per: 20},
        {name: '21', value: 200, per: 20},
        {name: '13', value: 200, per: 20},
        {name: '14', value: 200, per: 20},
        {name: '15', value: 200, per: 20},
        {name: '16', value: 200, per: 20},
        {name: '17', value: 200, per: 20},
        {name: '18', value: 200, per: 20}
      ],
      typeCount: 0,
      informationList: [],
      informationCount: 0,
      man: 0,
      woman: 0,
      ageList: [],
      ageName: [],
      labelId: null,
      typeList: [],
      childrenTypeList: [],
      changeValue: [],
      changeName: [],
      depth:''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init() {
      this.depth = this.$globalData.userInfo.depth
      this.getInformation()
      this.getManWoman()
      this.getLabel()
      this.getAge()
      this.getChange()
    },
    lookInfo (childrenList) {
      childrenList.forEach(item => {
        item['name'] = item.label
        item['value'] = item.num
      });
      this.childrenTypeList = childrenList
      this.show = true
    },
    orgChange (id, isStreet) {
      this.grid = id
      this.isStreet = isStreet
      this.childrenTypeList = []
      this.init()
    },
    getInformation () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/statistics/orgRegistry'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.depth == 5 ? this.$globalData.userInfo.orgId : this.grid ? this.grid : this.$globalData.userInfo.orgId ,
          gridId: this.depth == 4 ? null : this.grid
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let per1, per2, per3, per4
          let num1, num2, num3, num4, sum
          data.list.map(item => {
            if (item.label == '常住人口') {
              num1 = item.count
              per1 = item.count ? parseInt((item.count / item.sum) * 100) : 0
            } else if (item.label == '实有人口') {
              num2 = item.count
              per2 = item.count ? parseInt((item.count / item.sum) * 100) : 0
            } else if (item.label == '户籍人口') {
              num3 = item.count
              per3 = item.count ? parseInt((item.count / item.sum) * 100) : 0
            } else if (item.label == '流动人口') {
              num4 = item.count
              per4 = item.count ? parseInt((item.count / item.sum) * 100) : 0
            } else if (item.label == 'sum') {
              sum = item.count
            }
          })
          this.informationList = [
            {name: '常住人口', value: num1, percent: per1 + '%'},
            {name: '实有人口', value: num2, percent: per2 + '%'},
            {name: '户籍人口', value: num3, percent: per3 + '%'},
            {name: '流动人口', value: num4, percent: per4 + '%'}
          ]
          this.informationCount = sum
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getManWoman () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/res/menWomen'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.depth == 5 ? this.$globalData.userInfo.orgId : this.grid ? this.grid : this.$globalData.userInfo.orgId ,
          gridId: this.depth == 4 ? null : this.grid > 0 ? this.grid : null
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.man = parseInt(data.manNum)
          this.woman = parseInt(data.woManNum)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getAge () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/res/age'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.depth == 5 ? this.$globalData.userInfo.orgId : this.grid ? this.grid : this.$globalData.userInfo.orgId ,
          gridId: this.depth == 4 ? null : this.grid + ''
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.ageName = Object.keys(data.data)
          this.ageList = Object.values(data.data)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getLabel () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/res/label'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.depth == 5 ? this.$globalData.userInfo.orgId : this.grid ? this.grid : this.$globalData.userInfo.orgId ,
          gridId: this.depth == 4 ? null : this.grid,
          parentLabelId: this.labelId || null
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          data.data.forEach(item => {
            item['name'] = item.label
            item['value'] = item.num
          });
          if (this.childrenTypeList.length < 1) {
            this.typeCount = data.data.map(item => item.num).reduce((a, b) => a+b)
            this.typeList = data.data
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getChange () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/res/change'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.depth == 5 ? this.$globalData.userInfo.orgId : this.grid ? this.grid : this.$globalData.userInfo.orgId ,
          gridId: this.depth == 4 ? null : this.grid
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let changeName = data.data.czList.map(item => item.month)
          let changeValue = [{name: '常住人口', value: []}, {name: '流动人口', value: []}]
          changeValue[0].value = data.data.czList.map(item => item.resCount)
          changeValue[1].value = data.data.ldList.map(item => item.resCount)
          this.changeName = changeName
          this.changeValue = changeValue
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.dialog_info {
  padding: 20px;
  flex: 1;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      display: flex;
      margin-top: 35px;
      .left_dian {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .color0 {background-color: #387FF5;}
        .color1 {background-color: #23D87B;}
        .color2 {background-color: #FFC955;}
        .color3 {background-color: #FF5555;}
      }
      .right_text {
        flex: 1;
        p {
          font-size: 26px;
          margin: 0;
          color: #666;
        }
        span {
          display: inline-block;
          color: #999;
          font-size: 28px;
        }
      }
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  height: 400px;
}
.user_age {
  height: 500px;
}
.peple_change {
  height: 500px;
}
</style>
